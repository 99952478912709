.library-background-container {
    background-image: url('https://s3.amazonaws.com/year-in-review.booksloth.com/static/media/background_image_1440.cf846c5d.svg');
    background-size: cover;
}
​
.library-background-container .row{
    padding: 30px 0;
}
​
.library-background-container .main-container {
    max-width: 80%;
}
​
.library-background-container .rating-txt p{
    text-align: center;
}
​
.library-background-container .rating-img{
    text-align: center;
}
.library-background-container .rating-img .sloth-stats {
    max-width: 50%;
}

​
@media (min-width: 850px) { 
​
.library-background-container {
    background-size: contain;
}
​
.library-background-container .rating-txt p{
    text-align: left;
}
​
.library-background-container .row{
    padding: 60px 0;    
    display: flex;
    align-items: center;
    justify-content: center;
}
}