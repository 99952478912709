.Book-Item-Macro {
    width: fit-content;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
}

.Book-Item-Sub-Macro {
    width: fit-content;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
}

.Book-Item-Micro-A {
    justify-content: center;
    padding: 10px;
}

.Book-Item-Micro-B {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.Book-Item-Header {
    display: inline-block;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    text-align: center;
    color: #4a4a4a;
}

.Book-Item-Caption {
    display: inline-block;
    font-size: 24px;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    text-align: center;
    padding-top: 16px;
    color: #4a4a4a;
}

.Book-Item-Image {
    width: 165px;
    margin: 0 auto;
}

@media only screen and (max-width: 1280px) {
    .Book-Item-Macro {
        width: fit-content;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
    .Book-Item-Sub-Macro-B {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-wrap: wrap;
    }
    .Book-Item-Header {
        font-size: 18px;
        text-align: center;
    }
    .Book-Item-Caption {
        font-size: 24px;
        text-align: center;
    }
    .Book-Item-Image {
        width: 165px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 960px) {
    .Book-Item-Macro {
        width: fit-content;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
    .Book-Item-Sub-Macro-B {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-wrap: wrap;
    }
    .Book-Item-Header {
        font-size: 18px;
        text-align: center;
    }
    .Book-Item-Caption {
        font-size: 24px;
        text-align: center;
    }
    .Book-Item-Image {
        width: 165px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 840px) {
    .Book-Item-Macro {
        width: fit-content;
        max-width: 250px;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
    .Book-Item-Sub-Macro-B {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        text-align: center;
        flex-wrap: wrap;
    }
    .Book-Item-Header {
        font-size: 18px;
        text-align: center;
    }
    .Book-Item-Caption {
        font-size: 24px;
        text-align: center;
    }
    .Book-Item-Image {
        width: 165px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 600px) {
    .Book-Item-Macro {
        width: 100%;
        display: block;
        flex-direction: row;
        max-width: 100%;
    }
    .Book-Item-Sub-Macro {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }
    .Book-Item-Micro-B {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        width: 100%;
    }
    .Book-Item-Header {
        font-size: 18px;
        text-align: center;
    }
    .Book-Item-Caption {
        font-size: 20px;
        text-align: left;
    }
    .Book-Item-Image {
        width: 165px;
    }
}

@media only screen and (max-width: 480px) {
    .Book-Item-Header {
        font-size: 18px;
        text-align: center;
    }
    .Book-Item-Caption {
        font-size: 16px;
    }
    .Book-Item-Image {
        width: 165px;
    }
}