.Top-Books-Section-Macro {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5% 0;
}

.Top-Books-Section-Sub-Macro {
    display: flex;
    width: inherit;
    flex-direction: column;
    align-self: center;
    margin-right: 12%;
    margin-left: 12%;
}

.Top-Books-Section-Micro-A {
    display: flex;
    width: inherit;
    flex-direction: column;
    align-self: center;
    /* padding: 2%; */
}

.Top-Books-Section-Micro-B {
    display: flex;
    width: inherit;
    flex-direction: row;
    align-self: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.Top-Books-Section-Header-Text {
    display: inline-block;
    font-size: 44px;
    font-family: 'Nunito', sans-serif;
    line-height: 50px;
    font-weight: 700;
    text-align: center;
    /* padding-top: 24px; */
    color: #4a4a4a;
}

.Top-Books-Section-Caption-Text {
    display: inline-block;
    font-size: 26px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
    padding-top: 16px;
    color: #4a4a4a;
}

.Top-Books-Section-Image {
    width: 15%;
    min-width: 100px;
    padding: 2%;
}

@media only screen and (max-width: 1280px) {
    .Top-Books-Section-Image {
        width: unset;
        min-width: 100px;
    }

    .Top-Books-Section-Header-Text {
        display: inline-block;
        font-size: 44px;
        line-height: 50px;
    }
    
    .Top-Books-Section-Caption-Text {
        display: inline-block;
        font-size: 26px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 960px) {
    .Top-Books-Section-Sub-Macro {
        display: flex;
        width: inherit;
        flex-direction: column;
        align-self: center;
        margin-right: 16px;
        margin-left: 16px;
    
    }
    .Top-Books-Section-Image {
        width: unset;
        min-width: 100px;
    }

    .Top-Books-Section-Header-Text {
        display: inline-block;
        font-size: 44px;
        line-height: 50px;
    }
    
    .Top-Books-Section-Caption-Text {
        display: inline-block;
        font-size: 26px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 840px) {
    .Top-Books-Section-Sub-Macro {
        display: flex;
        width: inherit;
        flex-direction: column;
        align-self: center;
        margin-right: 16px;
        margin-left: 16px;
    
    }
    .Top-Books-Section-Header-Text {
        font-size: 40px;
        line-height: 50px;
    }
    
    .Top-Books-Section-Caption-Text {
        font-size: 22px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .Top-Books-Section-Sub-Macro {
        flex-flow: row wrap;
    
    }
    
    .Top-Books-Section-Header-Text {
        font-size: 40px;
        line-height: 50px;
    }
    
    .Top-Books-Section-Caption-Text {
        font-size: 22px;
        line-height: 30px;
    }

    .Top-Books-Section-Image {
        padding: 2%;
    }
}

@media only screen and (max-width: 480px) {
    .Top-Books-Section-Sub-Macro {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    
    }
    .Top-Books-Section-Header-Text {
        font-size: 40px;
        line-height: 50px;
    }
    
    .Top-Books-Section-Caption-Text {
        font-size: 22px;
        line-height: 30px;
    }
    .Top-Books-Section-Image {
        width: 120px;
    }

    
}

@media only screen and (max-width: 360px) {
    .Top-Books-Section-Sub-Macro {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    
    }
    .Top-Books-Section-Header-Text {
        font-size: 40px;
        line-height: 50px;
    }
    
    .Top-Books-Section-Caption-Text {
        font-size: 22px;
        line-height: 30px;
    }

    
    
}