@media only screen and (max-width: 840px) {
    .Social-Media-Macro-Container {
        background-size: contain;
    }
}

.Social-Media-Macro-Container {
    height: 100%; 
    background-image: url("https://dev-www.booksloth-apis.com/social-media-background.png");    
    background-repeat: no-repeat;
    background-size: cover;
}

.Social-Media-Background-Image {
    width: 100%;
    overflow: hidden;
    z-index: 999;
    display: flex;
    flex-direction: row;
}

.Social-Media-Sub-Macro-Container {
    padding: 80px 0; /* position: absolute; */
    display: flex;
    width: 100%;
    align-content: center;
    /* margin-bottom: 400px; */
    /* margin-top: 9%; */
    flex-direction: row-reverse;
}

.Social-Media-Content-Container {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    
    height: 400px;
    margin-right: 20%;
}

.Social-Media-Title {
    display: inline-block;
    font-size: 44px;
    font-family: 'Nunito', sans-serif;
    line-height: 80px;
    font-weight: 700;
    color: white;
}
.Social-Media-Sub-Title {
    display: inline-block;
    font-size: 42px;
    font-family: 'Nunito', sans-serif;
    line-height: 36px;
    font-weight: 500;
    color: #A852D7;
}

.Social-Media-Icon-Container {
    display: flex;
    flex-direction: row;
    padding-top: 24px;
    width: 100% ;
}

.Social-Media-Icon-Buttons {
    width: 50px;
    height: 50px;
    margin-right: 25px; 
}

.Social-Media-Icon-Buttons.last-child {
    margin: 0;
}