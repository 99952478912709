.Nav-Bar-Macro-Content {
    margin-left: 12%;
    margin-right: 12%;
    height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between; 
    
}


.Nav-Bar-Macro {
    background: rgba(255, 255, 255, 0.7);
    height: 50px;
    width: 100vw;
    position: fixed;
    backdrop-filter: blur(8px);
    z-index: 1;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);
}

.Nav-Bar-Action-Container {
    display: inline-block;
    align-content: center;
    text-align: center;
    vertical-align: middle;
}

.Nav-Bar-Logo {
    width: auto;
    height: 36px;
}

.Nav-Bar-Side-Menu-Icon-Container {
    margin-right: 10px;
    height: 100%;
}

.Nav-Bar-Side-Menu-Icon {
    width: 24px;
    height: 24px;
    margin: 5px;
    display: none;
}

.Nav-Bar-Link, .Nav-Bar-Booksloth-Link {
    display: inline-block;
    padding-left: 24px;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    line-height: 36px;
    font-weight: 500;
    color: #57325E;
}

.Nav-Bar-Button-Group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    justify-items: center;
    vertical-align: middle;
}

.Nav-Bar-Text-Group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Nav-Bar-Button {
    border-radius: 18px;
    height: 36px;
    padding: 8px 20px 8px 20px;
}

.Nav-Bar-Button-contained {
    border-radius: 18px;
    height: 36px;
    padding: 0px 20px 0px 20px;
    background-color: #A852D7;
}

.Nav-Bar-Button-outlined {
    border-radius: 18px;
    height: 36px;
    padding: 0px 20px 0px 20px;
    background-color: transparent;
    margin-left: 15px;
    color: #A852D7;
    border: 3px solid #A852D7;
}


@media only screen and (min-width: 960px) {

}

@media only screen and (max-width: 1280px) {
    
    .Nav-Bar-Macro-Content {
        margin-right: 5%;
        margin-left: 5%;
    }
}

@media only screen and (max-width: 960px) {
    .Nav-Bar-Side-Menu-Icon {
        display: block;
    }

    .Nav-Bar-Macro-Content {
        margin-left: 16px;
        margin-right: 16px;
    }
    
    .Nav-Bar-Button-contained, .Nav-Bar-Link {
        display: none;
    }

    .Nav-Bar-Button-outlined {
        border: none;
        padding: 0px;
    }

    .Nav-Bar-Booksloth-Link {
        padding: 0px;
        font-weight: 550;
        margin-left: 5px;
        padding-top: 1px;
    }
    
}
@media only screen and (max-width: 480px) {}
@media only screen and (max-width: 360px) {}

