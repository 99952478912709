@import url("https://fonts.googleapis.com/css?family=Chivo:700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700,800,900&display=swap");
html,
body {
height: 100%;
width: 100%;
margin: 0;
padding: 0;
font-size: 62.5%;
}

.hero-container,
.bookclub-hero-container,
.year-hero-container,
.footer,
.footer-links,
.user-stats,
.share-friends {
padding-top: 20px;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
background: #1ba79f;
background: -moz-linear-gradient(45deg, #1cc5dd 0%, #8e42d1 100%) fixed; /* FF3.6-15 */
background: -webkit-linear-gradient(45deg, #1cc5dd 0%, #8e42d1 100%) fixed; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(45deg, #1cc5dd 0%, #8e42d1 100%) fixed; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1CC5DD', endColorstr='#8E42D1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.user-stats .total-stats {
margin-top: 60px;
margin-bottom: -15%;
background: #f4f4f4;
padding: 3%;
border-radius: 25px;
}
.user-stats .total-stats h1,
.user-stats .total-stats h2,
.five-stars .main-container h1,
.five-stars .main-container h2,
.top-genres .main-container h1,
.top-genres .main-container h2,
.top-authors .main-container h1,
.top-authors .main-container h2,
.bs-top-books .main-container h1,
.bs-top-books .main-container h2 {
color: #333;
text-align: center;
}

.share-friends .main-container .social-sharing {
text-align: center;
}
​.social-sharing button {
display: inline-block;
margin-right: 20px;
background: transparent;
}

.social-sharing .twitter {
display: inline-block;
vertical-align: top;
margin-left: 20px;
}
​ .social-sharing .twitter svg circle {
display: none;
}

.discussions-main-container {
max-width: 800px;
margin: 0 auto;
padding-top: 30px;
}

.discussions-main-container .thread-container {
margin-bottom: 20px;
border-bottom: 2px solid lightgrey;
}

.discussions-main-container .post-container {
margin-bottom: 10px;
padding: 20px;
border: 1px solid #e5ecf1;
}

.discussions-main-container .post-container:hover {
background: #f4f8fa;
cursor: pointer;
}

.discussions-main-container .reply-container {
padding-left: 10px 20px 5px;
margin-left: 30px;
border-left: 2px solid #CAD6DE;
}

.discussions-main-container .post-container .main-post-photo {
display: inline-block;
vertical-align: middle;
width: 10%;
}

.discussions-main-container .post-container .main-post-text {
display: inline-block;
vertical-align: top;
width: 90%;
}

.discussions-main-container .post-container .main-post-text .user-name {
padding-bottom: 5px;
}

.discussions-main-container .post-container .main-post-text .main-post-actions {
padding-top: 20px;
color: #A994BF;
}

.discussions-main-container .post-container .main-post-text .main-post-actions .main-post-reply{
display: inline-block;
padding-right: 20px;
}

.discussions-main-container .post-container .main-post-text .main-post-actions .main-post-reply:hover {
color: #AF52DE;
}

.discussions-main-container .post-container .main-post-text .main-post-actions .main-post-type:hover {
background: #AF52DE;
color: #fff;
}

.discussions-main-container .post-container .main-post-text .main-post-actions .main-post-type{
display: inline-block;
background: #EDEFF1;
padding: 3px 5px;
border-radius: 5px;
}

.discussions-main-container .post-container .reply-post-photo {
display: inline-block;
vertical-align: middle;
width: 10%;
}

.discussions-main-container .post-container .reply-post-text {
display: inline-block;
vertical-align: top;
width: 90%;
}

.discussions-main-container .post-container .reply-post-text .user-name {
padding-bottom: 5px;
}


.discussions-main-container .post-container h1{
font-family: 'San Francisco', Helvetica, Arial, san-serif;
font-weight: 600;
font-size: 15px;
}

.discussions-main-container .post-container p{
font-family: 'San Francisco', Helvetica, Arial, san-serif;
font-weight: 400;
font-size: 15px;
}

.discussions-main-container .post-container img{
border-radius:50%;
image-orientation: from-image;
}







.user-stats .total-stats .number,
.top-genres .number,
.bs-numbers .number {
color: #8e42d1;
}

.top-badges .main-container .notification.number {
color: #fff;
font-size: 30px;
background: #8e42d1;
border-radius: 50%;
padding: 1%;
position: absolute;
top: -10%;
right: 35%;
}

.top-badges .main-container .col {
position: relative;
}



.five-stars .five-star-books {
text-align: center;
padding: 20px;
}

.five-stars .main-container .fav-book {
max-width: 160px;
max-height: 200px;
padding: 10px;
}

/* .top-genres,
.bs-top-books {
margin-bottom: 40px;
} */

.top-genres .main-container .fav-genre .carousel-caption, 
.bs-top-books .main-container .fav-genre .carousel-caption {
position: relative;
right: auto;
bottom: auto;
left: auto;
}

.top-genres .main-container .fav-genre img,
.bs-top-books .main-container .fav-genre img {
max-width: 50%;
margin: 0 auto;
}

.top-genres .main-container .fav-genre h1,
.top-genres .main-container .fav-genre h2,
.bs-top-books .main-container .fav-genre h1,
.bs-top-books .main-container .fav-genre h2 {
text-shadow: none;
text-align: left;
}

.top-genres .main-container .carousel,
.bs-top-books .main-container .carousel {
margin-top: 40px;
}

.top-genres .main-container .fav-genre .fav-genre-inner,
.bs-top-books .main-container .fav-genre .fav-genre-inner {
    max-width: 65%;
    margin: 0 auto;
}

.top-genres .main-container .fav-genre h1,
.bs-top-books .main-container .fav-genre h1 {
font-size: 44px;
}

.top-genres .main-container .fav-genre h2,
.bs-top-books .main-container .fav-genre h2 {
font-size: 20px;
}

.top-genres .main-container .carousel-inner,
.bs-top-books .main-container .carousel-inner {
overflow: visible;
}

.top-genres .main-container .carousel-indicators,
.bs-top-books .main-container .carousel-indicators {
bottom: 0;
}

.top-genres .main-container .carousel-indicators li,
.bs-top-books .main-container .carousel-indicators li {
border: 1px solid #8e42d1;
}

.top-genres .main-container .carousel-indicators .active,
.bs-top-books .main-container .carousel-indicators .active {
background-color: #8e42d1;
}

/* .bs-top-books .main-container {
padding-bottom: 60px;
} */

.bs-top-books .main-container .fav-genre .number {
color: #8e42d1;
font-family: "Chivo", sans-serif;
font-size: 8.5vw;
}


.top-authors {
padding-top: 0;
}

.top-authors .fav-author-1 h1,
.top-authors .fav-author-2 h1,
.top-authors .fav-author-3 h1 {
color: #8e42d1;
}

.top-authors .fav-author-2 h1 {
font-size: 44px;
}

.top-authors .fav-author-3 h1 {
font-size: 20px;
}

.top-authors h2 {
padding-bottom: 30px;
}

.share-friends .main-container {
padding: 60px 0;
}

.share-friends .main-container h1,
.share-friends .main-container h2 {
text-align: center;
color: #fff;
}

.share-friends .main-container .social-media {
text-align: center;
padding: 30px 0;
}

.share-friends .social-media button {
    margin: 0 10px;
}

.share-friends .main-container .social-media .social-logo {
padding: 0 20px;
}

.hero-container .float-left,
.bookclub-hero-container .float-left {
padding-bottom: 40px;
}

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fd7025+0,c2db56+34,1ba79f+100 */

.year-hero-container .year h1.big-year {
font-family: "Chivo", sans-serif;
font-size: 225.5px;
}

.year-hero-container .main-container .modal-header {
border-bottom: none;
}

.year-hero-container .main-container .modal-body p {
color: #333;
font-size: 16px;
padding-top: 20px;
width: 50%;
margin: 0 auto;
}

.year-hero-container .main-container .modal-body a {
color: #8e42d1;
font-size: 16px;
}

.year-hero-container .main-container .modal-footer {
border-top: none;
}

.year-hero-container .main-container button.sign-up {
border-radius: 18px;
height: 36px;
padding: 0px 20px 0px 20px;
background-color: #A852D7;
margin-bottom: 20px;
}

.year-hero-container .main-container button.close {
margin: 10px;
}

.year-hero-container .main-container .modal-title {
color: #333;
font-size: 22px;
}

.year-hero-container .main-container .modal-body .login {
width: 200px;
display: block;
margin: 0 auto;
padding-bottom: 10px;
}

.year-hero-container .main-container .modal-body button.login {
background: transparent;
border: 1px solid #8e42d1;
color: #8e42d1;
padding: 10px;
}

.year-hero-container .main-container #loginEmail form input,
.year-hero-container .main-container #signupEmail form input {
font-family: "Nunito", sans-serif;
font-size: 14px;
width: 200px;
border-radius: 15px;
margin-bottom: 10px;
padding: 5px;
border: 1px solid lightgray;
padding-left: 10px;
}

.year-hero-container .main-container #loginEmail form a,
.year-hero-container .main-container #signupEmail form a {
font-size: 14px;
}

.year-hero-container .main-container #loginEmail form button,
.year-hero-container .main-container #signupEmail form button {
display: block;
width: 200px;
margin: 0 auto;
padding: 5px;
margin-top: 10px;
}

.user-stats .number,
.top-genres .number,
.top-badges .notification.number,
.bs-numbers .number,
.bs-top-books .number {
display: inline-block;
font-family: "Chivo", sans-serif;
font-size: 40px;
}

.main-container .float-left img.app-store {
margin: 20px auto 15px;
display: block;
max-width: 200px;
}

.main-container {
max-width: 1150px;
width: 80%;
margin: 0 auto;
text-align: center;
}

.main-container .nav-links {
display: block;
max-width: 100%;
}

.year-hero-container .main-container .nav-links a {
vertical-align: middle;
}

.main-container .nav-links .nav-right a {
transition: all 2s ease;
}

.main-container .nav-links .nav-right a:hover {
text-decoration: none;
position: relative;
top: -2px;
}

.main-container .nav-right {
width: 100%;
display: inline-block;
text-align: center;
}

.main-container .nav-link {
display: inline-block;
height: 25px;
vertical-align: middle;
}

.main-container .nav-link img {
height: 25px;
margin: 0px 20px 20px 0;
}
.main-container .nav-links p {
margin: 0 20px;
font-family: "Lato", sans-serif;
font-weight: 400;
font-size: 16px;
color: #fff;
}

.main-container .logo {
margin: 30px auto 20px;
max-width: 160px;
width: 90%;
}

.main-container .app {
width: 100%;
max-width: 330px;
}

.main-container h1 {
margin: 15px auto;
font-family: "Nunito", sans-serif;
font-weight: 800;
font-size: 40px;
color: #fff;
}

.main-container .big-year {
margin: 15px auto;
font-family: "Nunito", sans-serif;
font-weight: 800;
font-size: 25vw;
color: #fff;
}

.main-container h2 {
margin: 15px auto;
font-family: "Lato", sans-serif;
font-weight: 300;
font-size: 22px;
color: #fff;
line-height: 38px;
}

.main-container h3 {
margin: 15px auto;
font-family: "Lato", sans-serif;
font-weight: 400;
font-size: 24px;
color: #fff;
}

.main-container p {
text-align: center;
margin-bottom: 20px;
font-family: "Lato", sans-serif;
font-size: 22px;
line-height: 26px;
font-weight: lighter;
color: #fff;
}

.hero-container,
.bookclub-hero-container{
padding-bottom: 14%;
}


.user-stats {
/* padding-bottom: 60px; */
margin-bottom: 15%;
}

.user-posts {
/* padding-bottom: 60px; */
margin-bottom: 15%;
}

.hero-container .floats,
.bookclub-hero-container .floats {
padding-top: 20px;
}

.hero-container .floats h1,
.bookclub-hero-container .floats h1 {
font-size: 42px;
margin-top: 5%;
}

.hero-container .floats h2,
.bookclub-hero-container .floats h2 {
font-size: 24px;
}

.hero-container .floats .float-left p,
.bookclub-hero-container .floats .float-left p {
padding-top: 10px;
}

.main-container .float-left .media-1 {
height: 25px;
margin-right: 20px;
max-width: 100px;
}
.main-container .float-left .media-2 {
height: 25px;
margin-top: 5px;
max-width: 150px;
}

button {
background-color: #8e42d1;
border: none;
color: white;
padding: 15px 32px;
border-radius: 50px;
font-family: "Lato", sans-serif;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 18px;
transition: all 2s ease;
}

button:hover {
text-decoration: none;
position: relative;
top: -2px;
}

.app-store {
transition: all 2s ease;
}

.app-store:hover {
text-decoration: none;
position: relative;
top: -2px;
}

#mc_embed_signup {
border: none;
text-align: center;
width: 100%;
} /* Signup form container */

.mc-field-group {
display: inline-block;
} /* positions input field horizontally */

#mce-EMAIL {
font-family: "Lato", sans-serif;
font-size: 2em;
border: 2px solid #fff;
color: #333;
background-color: #fff;
padding: 0.7em 1em;
width: 220px;
-webkit-border-radius: 2px;
-moz-border-radius: 2px;
border-radius: 10px;
display: inline-block;
margin: 0 10px;
} /* Input Styles */

.clear {
display: inline-block;
} /* positions button horizontally in line with input */

#mc_embed_signup .button {
font-family: "Lato", sans-serif;
font-size: 2em;
letter-spacing: 0.03em;
color: #fff;
background-color: #8e42d1;
padding: 0.7em 1em;
border: 2px solid #8e42d1;
max-width: 100px;
-webkit-border-radius: 3px;
-moz-border-radius: 3px;
border-radius: 10px;
display: inline-block;
margin: 10px auto;
} /* Button Styles */

:-webkit-input-placeholder {
color: #333;
} /* WebKit browsers */
:-moz-placeholder {
color: #333;
} /* Mozilla Firefox 4 to 18 */
::-moz-placeholder {
color: #333;
} /* Mozilla Firefox 19+ */
:-ms-input-placeholder {
color: #333;
} /* Internet Explorer 10+ */

/* .steps .main-container {
padding-top: 80px;
} */

.steps .main-container,
.top-badges .main-container,
.bs-numbers .main-container {
text-align: center;
/* margin-top: 40px; */
}

.steps .main-container .row,
.top-badges .main-container .row {
padding-bottom: 0px;
}

.steps .main-container .row .icon {
max-width: 142px;
}

.top-badges .main-container .row .fav-badge,
.bs-numbers .main-container .row .fav-badge {
max-width: 115px;
}

.steps .main-container .row .col-xs-12,
.top-badges .main-container .row .col-xs-12,
.bs-numbers .main-container .row .col-xs-12 {
padding-bottom: 30px;
}

.steps .main-container h1,
.top-badges .main-container h1,
.bs-numbers .main-container h1 {
font-size: 40px;
color: #333;
margin-top: 20px;
}

.steps .main-container p,
.top-badges .main-container h2,
.bs-numbers .main-container h2 {
color: #333;
}

.top-badges .main-container h2,
.bs-numbers .main-container h2 {
padding-bottom: 40px;
}

.features {
background-color: #fff;
}

.features .main-container .row {
padding-top: 20px;
}

.features .main-container .first-row .featured-profile {
max-width: 90%;
display: block;
margin: 0 auto;
}

.features .main-container .row .featured-app {
max-width: 280px;
}

.features .main-container h1,
.features .main-container p {
color: #333;
text-align: center;
}

.features .row .discover-img,
.features .row .rating-img {
text-align: center;
}

.footer {
margin-top: 40px;
}

.footer .main-container {
text-align: center;
}

.footer .main-container .modal .modal-header {
border-bottom: none;
}

.footer .main-container .modal-body h1,
.footer .main-container .modal-body h2 {
color: #333;
}

.footer .main-container .modal-content .modal-header,
.footer .main-container .modal-content .modal-body {
padding: 0;
}

.footer .main-container .modal-content button.close {
margin: 10px;
}

.footer .main-container .modal-body button {
padding: 10px 30px;
}

.footer
.main-container
.modal-body
input[type="number"]::-webkit-inner-spin-button,
.footer
.main-container
.modal-body
input[type="number"]::-webkit-outer-spin-button {
-webkit-appearance: none;
}

.footer .main-container .modal-body input {
font-family: "Chivo", sans-serif;
font-size: 2.5vw;
color: #8e42d1;
text-align: center;
border: none;
border-bottom: 2px solid #8e42d1;
width: 100px;
}

.footer .main-container .modal-body input:focus {
outline: none;
}

.footer .main-container .download-bs {
padding: 30px 0;
}

.footer .main-container .moodal.modal-footer {
border-top: none;
}

.footer .main-container button {
margin-top: 20px;
margin-bottom: 60px;
}

.footer .app-download {
text-align: center;
}

.footer .app-download a {
display: block;
padding: 15px 0;
}

.footer h3 {
text-align: center;
}

.footer-links {
padding-top: 60px;
bottom: 0;
left: 0;
right: 0;
z-index: 100;
}

.footer-links p {
margin: 0;
padding-right: 20px;
display: inline-block;
font-size: 12px;
text-align: center;
display: block;
padding-bottom: 20px;
}

.footer-links .social-media {
text-align: center;
}

.footer-links .social-media p {
display: block;
padding-top: 15px;
}

.footer-links .social-logo {
display: inline-block;
}

.footer-links .social-logo img {
height: 30px;
margin: 0px 10px 10px 0;
}

.footer-links .links a p:hover {
text-decoration: underline;
}

.footer .footer-links .links {
text-align: center;
}

.footer .footer-links .links p {
text-align: center;
display: inline-block;
padding-bottom: 20px;
}

.terms-privacy h2 {
font-size: 24px;
font-weight: 800;
}

.terms-privacy p {
text-align: left;
}

.terms-privacy li {
list-style: none;
}

.terms-privacy p span {
font-weight: 800;
}

.terms-privacy .footer-links p {
color: #fff;
text-align: center;
}

.terms-privacy .footer-links .links {
text-align: center;
}

.terms-privacy .footer-links .links a p:hover {
text-decoration: underline;
}


.top-genres .main-container .fav-genre h1,
.top-genres .main-container .fav-genre h2,
.bs-top-books .main-container .fav-genre h1,
.bs-top-books .main-container .fav-genre h2 {
text-shadow: none;
text-align: center;
}

@media (min-width: 850px) {
/* positions input field and button underneath each other with 100% width for tablet and mobile */

​.hero-container,
.footer,
.bookclub-hero-container,
.year-hero-container {
    padding-top: 50px;
}

.hero-container .float-left,
.bookclub-hero-container .float-left {
    padding-bottom: 0;
}

.main-container .nav-right {
    width: 70%;
    display: inline-block;
    text-align: right;
}

.main-container {
    width: 100%;
    text-align: left;
}

.year-hero-container .main-container,
.user-stats .main-container {
    text-align: center;
}

.year-hero-container .main-container,
.user-stats .main-container {
    text-align: center;
}


.top-genres .main-container .fav-genre h1,
.top-genres .main-container .fav-genre h2,
.bs-top-books .main-container .fav-genre h1,
.bs-top-books .main-container .fav-genre h2 {
    text-align: left;
}

/* .top-authors {
    padding-top: 60px;
    padding-bottom: 60px;
} */

.top-badges .main-container .notification.number {
    padding: 1.5%;
    position: absolute;
    top: -5%;
    right: 40%;
}

.main-container .app {
    margin-left: 0;
}

.main-container .float-left {
    width: 70%;
    z-index: 2;
    bottom: 0;
    display: inline-block;
}

.main-container .app {
    margin-left: 15%;
}

.hero-container .floats h1,
.bookclub-hero-container .floats h1 {
    font-size: 72px;
    margin-top: 10%;
}

.main-container .float-left p {
    padding-top: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.hero-container .floats .float-left p,
.bookclub-hero-container .floats .float-left p {
    padding-top: 70px;
}

.main-container .float-left img.app-store {
    margin: 20px auto 15px;
    display: inline-block;
    max-width: 200px;
}

.main-container .float-left img.app-store.google {
    margin-left: 15px;
}

.main-container .float-left .media-1 {
    height: 25px;
    margin-right: 20px;
    max-width: 100px;
}
.main-container .float-left .media-2 {
    height: 25px;
    margin-top: 5px;
}

.main-container .float-right {
    width: 29%;
    position: relative;
    z-index: 100;
    display: inline-block;
    margin-bottom: 5%;
    float: right;
}

/* .steps .main-container .row,
.top-badges .main-container .row,
.bs-numbers .main-container .row {
    padding-bottom: 60px;
} */

.bs-top-books .main-container .fav-genre .number {
    font-size: 5.5vw;
}

.user-stats .number,
.top-genres .number,
.top-badges .notification.number,
.bs-numbers .number,
.bs-top-books .number {
    font-size: 40px;
}

.features .main-container .first-row {
    padding: 60px 0;
}

.features .main-container .first-row .featured-profile {
    max-width: 750px;
}

.features .main-container .row .featured-app {
    max-width: 350px;
}

.features .main-container .row {
    padding-top: 0px;
}

.features .main-container .row h1,
.features .main-container .row p {
    text-align: left;
}
.features .row .community-txt,
.features .row .rating-txt,
.features .row .profile-txt {
    padding-top: 10%;
    padding: 20% 50px 0;
}

.features .row .community-img,
.features .row .profile-img {
    float: left;
}

.features .row .community-txt,
.features .row .profile-txt {
    float: right;
}

.features .row .rating-img {
    float: right;
}

.features .row .rating-txt {
    float: left;
}

.footer .app-download {
    padding-bottom: 20px;
    text-align: center;
}

.footer .app-download a {
    display: inline-block;
    padding: 0;
}

.footer .app-download .google {
    padding-left: 20px;
}
}

@media (min-width: 990px) {
.top-badges .main-container .notification.number {
    padding: 2.5%;
    position: absolute;
    top: -5%;
    right: 30%;
}
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
display: block;
}
body {
line-height: 1;
}
ol,
ul {
list-style: none;
}
blockquote,
q {
quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
content: "";
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}

.badge-container h1 {
color: #333;
text-align: center;
color: #fff;
font-size: 44px;
background: #8e42d1;
border-radius: 50%;
padding: 1%;
position: absolute;
top: -10%;
right: 35%;
}