.Top-Books-Macro-Container {
    width: 100%;
    height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.Top-Books-Image-Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

}   

.Top-Books-Image {
    width: 100%;
}

.Top-Books-Text-Container {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-content: center;
    line-height: 24px;
    position: absolute;
    padding-bottom: 20%;
}

.Top-Books-Caption {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 28px;
    text-align: center;
    line-height: 38px;
    
}

.Top-Books-Header {
    font-family: 'Nunito', sans-serif;
    font-size: 52px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 30px;
}

@media only screen and (max-width: 1440px) {
    .Top-Books-Macro-Container {
        height: auto;
    }

    .Top-Books-Caption {
        font-size: 24px;
    }
    
    .Top-Books-Header {
        font-size: 52px;
    }
}

@media only screen and (max-width: 1280px) {
    .Top-Books-Image {
        width: 100%;
    }

    .Top-Books-Caption {
        font-size: 24px;
    }
    
    .Top-Books-Header {
        font-size: 52px;
    }
}

@media only screen and (max-width: 960px) {
    .Top-Books-Image {
        width: 100%;
    }

    .Top-Books-Text-Container {
        line-height: 36px;
        padding-bottom: 30%;
    }

    .Top-Books-Caption {
        font-size: 24px;
    }
    
    .Top-Books-Header {
        font-size: 52px;
    }
}

@media only screen and (max-width: 840px) {
    .Top-Books-Macro-Container {
        height: auto;
    }

    .Top-Books-Text-Container {
        line-height: 28px;
        padding-bottom: 25%;
    }

    .Top-Books-Caption {
        font-size: 22px;
        line-height: 38px;
        padding-bottom: 0px;
    }
    
    .Top-Books-Header {
        font-size: 40px;
        padding-bottom: 18px;
    }
}

@media only screen and (max-width: 600px) {
    .Top-Books-Text-Container {
        line-height: 28px;
        padding-bottom: 30%;
    }

    .Top-Books-Caption {
        font-size: 22px;
        line-height: 38px;
        padding-bottom: 0px;
    }
    
    .Top-Books-Header {
        padding-bottom: 12px;
    }
}

@media only screen and (max-width: 480px) {
    .Top-Books-Macro-Container {
        height: auto;
    }

    .Top-Books-Text-Container { 
        line-height: 24px;
        padding-bottom: 35%;
    }

    .Top-Books-Caption {
        font-size: 22px;
        line-height: 38px;
    }
    
    .Top-Books-Header {
        font-size: 40px;
        padding-bottom: 10px;
    }
}
