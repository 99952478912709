.UserBookStatistics-Section-Macro {
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;   
}

.UserBookStatistics-Section-Sub-Macro {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 12%;
    margin-right: 12%;
    padding: 4% 8%;
    background-color: #F3F4F5;
    width: 1440px;
    border-radius: 30px;
    justify-content: center;
}

.UserBookStatistics-Section-Sub-Macro-Top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 65px;
    width: 90%;
}

.UserBookStatistics-Section-Sub-Macro-Bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.UserBookStatistics-Section-Component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.UserBookStatistics-Section-Image {
    height: 180px;
}

.UserBookStatistics-Section-Count {
    display: inline-block;
    font-size: 42px;
    padding-top: 20px;
    font-family: 'Nunito', sans-serif;
    line-height: 70px;
    font-weight: 700;
    text-align: center;
    color: #A852D7;
}

.UserBookStatistics-Section-Title {
    display: inline-block;
    font-size: 26px;
    font-family: 'Nunito', sans-serif;
    line-height: 70px;
    font-weight: 700;
    text-align: center;
    color: #4a4a44;
}

.UserBookStatistics-Section-Header {
    display: inline-block;
    font-size: 44px;
    line-height: 60px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    text-align: left;
    color: #4a4a44;

}

.UserBookStatistics-Section-Macro .UserBookStatistics-Section-Header .stats-books-read {
    color: #a852d7;
    display: inline-block;
}

.UserBookStatistics-Section-Completed-Books {
    height: 220px;
}

@media (min-width: 850px){
    
    .UserBookStatistics-Section-Sub-Macro-Top {
        width: 75%;
    }
}


@media only screen and (max-width: 1280px) {
    .UserBookStatistics-Section-Sub-Macro {
        margin: 5%;
        padding: 4% 8%;
    }
}

@media only screen and (max-width: 960px) {

    .UserBookStatistics-Section-Image {
        height: 150px;
    }

    .UserBookStatistics-Section-Header {
        font-size: 42px;
        line-height: 50px;
    }
    .UserBookStatistics-Section-Completed-Books {
        height: 200px;
    }
}

@media only screen and (max-width: 840px) {
    .UserBookStatistics-Section-Sub-Macro {
        margin: 16px;
        padding: 4% 8%;
    }

    .UserBookStatistics-Section-Image {
        height: 100px;
    }

    .UserBookStatistics-Section-Header {
        font-size: 42px;
        line-height: 50px;
    }
    .UserBookStatistics-Section-Completed-Books {
        height: 150px;
        padding-right: 24px;
    }

    .UserBookStatistics-Section-Count {
        font-size: 32px;
        line-height: 50px;
        padding-top: 20px;
    }
    
    .UserBookStatistics-Section-Title {
        font-size: 24px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .UserBookStatistics-Section-Header {
        font-size: 24px;
        line-height: 30px;
    }
    .UserBookStatistics-Section-Completed-Books {
        height: 120px;
    }

    .UserBookStatistics-Section-Image {
        height: 75px;
    }

    .UserBookStatistics-Section-Count {
        font-size: 32px;
        line-height: 50px;
        font-weight: 700;
        padding-top: 20px;
    }
    
    .UserBookStatistics-Section-Title {
        font-size: 20px;
        line-height: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .UserBookStatistics-Section-Image {
        height: 60px;
    }

    .UserBookStatistics-Section-Header {
        font-size: 24px;
        line-height: 30px;
    }
    /* .UserBookStatistics-Section-Completed-Books {
        height: 80px;
    } */

    .UserBookStatistics-Section-Count {
        line-height: 50px;
        font-size: 20px;
        padding-top: 20px;
    }
    
    .UserBookStatistics-Section-Title {
        font-size: 20px;
        line-height: 30px;
    }
}





