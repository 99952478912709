.Download-Section-Macro-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Download-Section-Sub-Macro-A {
    display: flex;
    width: inherit;
    flex-direction: row;
    justify-content: center;
    padding-top: 5%;

}

.Download-Section-Sub-Macro-B {
    display: flex;
    width: inherit;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
}

.Download-Section-Sub-Macro-C {
    display: flex;
    width: inherit;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;
}

.Download-Section-Text {
    display: inline-block;
    font-size: 52px;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    color: #4a4a4a;
    padding: 40px 0 60px;
    text-align: center;
}

.Download-Section-Image {
    height: 400px;
}




@media only screen and (max-width: 1280px) {
    .Download-Section-Text {
        font-size: 44px;
        color: #4a4a4a;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 960px) {
    .Download-Section-Text {
        font-size: 52px;
    }

    .Download-Section-Sub-Macro-C {
        height: auto;
    }
    .Download-Section-Image {
        height: 300px;
    }
}

@media only screen and (max-width: 840px) {
    .Download-Section-Text {
        font-size: 52px;
    }

    .Download-Section-Image {
        height: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .Download-Section-Text {
        font-size: 42px;
    }
}

@media only screen and (max-width: 480px) {
    .Download-Section-Text {
        font-size: 40px;
        margin-bottom: 10px;
    }

    .Download-Section-Image {
        height: 200px;
    }
}