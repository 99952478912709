html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 62.5%;

}

/* .hero-container, .footer, .footer-links {
    padding-top: 20px;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background: #1ba79f;
    background: -moz-linear-gradient(45deg, #2193b0 0%, #3cddc9 100%) fixed; 
    background: -webkit-linear-gradient(45deg, #2193b0 0%,#3cddc9 100%) fixed; 
    background: linear-gradient(45deg, #2193b0 0%,#3cddc9 100%) fixed; 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2193b0', endColorstr='#3cddc9',GradientType=1 ); 
} */

.hero-container, .bookclub-hero-container, .year-hero-container, .footer, .footer-links, .user-stats, .share-friends {
    padding-top: 20px;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background: #1ba79f;
    background: -moz-linear-gradient(45deg, #1CC5DD 0%, #8E42D1 100%) fixed; /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #1CC5DD 0%,#8E42D1 100%) fixed; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1CC5DD 0%,#8E42D1 100%) fixed; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1CC5DD', endColorstr='#8E42D1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.user-stats .total-stats {
    margin-top: 60px;
    margin-bottom: -15%;
    background: #f4f4f4;
    padding: 3%;
    border-radius: 25px;
}
.user-stats .total-stats h1, .user-stats .total-stats h2, .five-stars .main-container h1, .five-stars .main-container h2,
.top-genres .main-container h1, .top-genres .main-container h2, .top-authors .main-container h1, .top-authors .main-container h2,
.bs-top-books .main-container h1, .bs-top-books .main-container h2{
    color: #333;
    text-align: center;
    padding-bottom: 20px;
}

.user-stats .total-stats .number, .top-genres .number, .bs-numbers .number{
    color: #8E42D1
}

.top-badges .main-container .notification.number {
    color: #fff;
    font-size: 30px;
    background: #8E42D1;
    border-radius: 50%;
    padding: 1%;
    position: absolute;
    top: -10%;
    right: 35%;
}


.five-stars {
    margin-top: 15%;
}

.five-stars .five-star-books {
    text-align: center;
    padding: 20px;
}

.five-stars .main-container .fav-book {
    max-width: 160px;
    max-height: 200px;
    padding: 10px;;
}


.top-genres .main-container .fav-genre img, 
.bs-top-books .main-container .fav-genre img {
    max-width: 30%;
    float: left;
}

.top-genres .main-container .fav-genre h1, .top-genres .main-container .fav-genre h2, 
.bs-top-books .main-container .fav-genre h1, .bs-top-books .main-container .fav-genre h2 {
    text-shadow: none;
    text-align: left;
}

.top-genres .main-container #myCarousel .left, .top-genres .main-container #myCarousel .right, 
.bs-top-books .main-container #myCarousel-2 .left, .bs-top-books .main-container #myCarousel-2 .right{
    background-image: none;
}

.top-genres .main-container #myCarousel .glyphicon-chevron-left, .top-genres .main-container #myCarousel .glyphicon-chevron-right,
.bs-top-books .main-container #myCarousel-2 .glyphicon-chevron-left, .bs-top-books .main-container #myCarousel-2 .glyphicon-chevron-right {
    color: #333;
}

.top-genres .main-container #myCarousel,
.bs-top-books .main-container #myCarousel-2 {
    padding-top: 40px;
    height: 300px;
}

.top-genres .main-container #myCarousel .fav-genre,
.bs-top-books .main-container #myCarousel-2 .fav-genre {
    max-width: 65%;
    margin: 0 auto;
}

.top-genres .main-container #myCarousel .fav-genre .carousel-caption,
.bs-top-books .main-container #myCarousel-2 .fav-genre .carousel-caption {
    float: right;
    margin: 10% 0;
    width: 60%;
    position: relative;
    padding: 0;
    left: 0;
    right: 0;
}

.top-genres .main-container #myCarousel .fav-genre .carousel-caption h1,
.bs-top-books .main-container #myCarousel-2 .fav-genre .carousel-caption h1{
    font-size: 44px;
} 

.top-genres .main-container #myCarousel .fav-genre .carousel-caption h2,
.bs-top-books .main-container #myCarousel-2 .fav-genre .carousel-caption h2  {
    font-size: 20px
}

.top-genres .main-container #myCarousel .carousel-inner,
.bs-top-books .main-container #myCarousel-2 .carousel-inner  {
    overflow: visible;
}

.top-genres .main-container #myCarousel .carousel-indicators,
.bs-top-books .main-container #myCarousel-2 .carousel-indicators {
    bottom: 0;
}

.top-genres .main-container #myCarousel .carousel-indicators li,
.bs-top-books .main-container #myCarousel-2 .carousel-indicators li {
    border: 1px solid #8E42D1; 
}
.top-genres .main-container #myCarousel .carousel-indicators .active,
.bs-top-books .main-container #myCarousel-2 .carousel-indicators .active {
    background-color: #8E42D1;
}

/* .bs-top-books .main-container {
    padding-bottom: 60px;
} */

.bs-top-books .main-container .fav-genre .number {
    color: #8E42D1;
    font-family: 'Chivo', sans-serif;
    font-size: 5.5vw;
}

.top-authors {
    padding-top: 0;
}

.top-authors .fav-author-1 h1, .top-authors .fav-author-2 h1, .top-authors .fav-author-3 h1 {
    color: #8E42D1;
}


.top-authors .fav-author-2 h1 {
    font-size: 44px;
} 

.top-authors .fav-author-3 h1 {
   font-size: 20px;
}

.top-authors h2 {
    padding-bottom: 30px;
}

.share-friends .main-container {
    padding: 60px 0;
}

.share-friends .main-container h1, .share-friends .main-container h2 {
    text-align: center;
    color: #fff;
}

.share-friends .main-container .social-media {
    text-align: center;
    padding: 30px 0; 
}

.share-friends .social-media button {
    margin: 0 10px;
}


.share-friends .main-container .social-media .social-logo{
    padding: 0 20px;
}


.hero-container .float-left, .bookclub-hero-container .float-left {
    padding-bottom: 40px;
}

/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fd7025+0,c2db56+34,1ba79f+100 */




.year-hero-container .year h1.big-year {
    font-family: 'Chivo', sans-serif;
    font-size: 25.5vw;
}

.year-hero-container .main-container .modal-header {
    border-bottom: none;
}

.year-hero-container .main-container .modal-body p{
    color: #333;
    font-size: 16px;
    padding-top: 20px;
    width: 50%;
    margin: 0 auto;
}

.year-hero-container .main-container .modal-body a{
    color: #8E42D1;
    font-size: 16px;
}

.year-hero-container .main-container .modal-footer {
    border-top: none;
}

/* .year-hero-container .main-container button.sign-up {
    background: transparent;
    border: 1px solid #fff;
    margin-left: 30px; 
} */

.year-hero-container .main-container button.close {
    margin: 10px;
}

.year-hero-container .main-container .modal-title{
    color: #333;
    font-size: 22px;
}

.year-hero-container .main-container .modal-body .login {
    width: 200px;
    display: block;
    margin: 0 auto;
    padding-bottom: 10px;
}

.year-hero-container .main-container .login .modal-title {
    margin-bottom: 0;
}

.year-hero-container .main-container .modal-body p{
    padding: 20px 0;
}


.year-hero-container .main-container .modal-body button.login {
    background: transparent;
    border: 1px solid #8E42D1;
    color: #8E42D1;
    padding: 10px;
}

.year-hero-container .main-container #loginEmail form input,.year-hero-container .main-container #signupEmail form input {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    width: 200px;
    border-radius: 15px;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid lightgray;
    padding-left: 10px;
}

.year-hero-container .main-container #loginEmail form a, .year-hero-container .main-container #signupEmail form a {
    font-size: 14px;
}

.year-hero-container .main-container #loginEmail form button, .year-hero-container .main-container #signupEmail form button{
    display: block;
    width: 200px;
    margin: 0 auto;
    padding: 5px;
    margin-top: 10px;
}

.user-stats .number, .top-genres .number, .top-badges .notification.number, .bs-numbers .number {
    display: inline-block;
    font-family: 'Chivo', sans-serif;
    font-size: 7.5vw;
}

.main-container .float-left img.app-store {
    margin: 20px auto 15px;
    display: block;
    max-width: 200px;
}

.main-container {
    max-width: 1150px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
}

.main-container .nav-links {
    display: block;
    max-width: 100%;
}

.main-container .nav-links .nav-right a{
    transition: all 2s ease;    
}

.main-container .nav-links .nav-right a:hover {
    text-decoration: none;
    position: relative;
    top: -2px;
}

.main-container .nav-right {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.main-container .nav-link {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
}

.main-container .nav-link img {
    height: 25px;
    margin: 0px 20px 20px 0;
}
.main-container .nav-links p{
    margin: 0 20px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size:16px;
    color: #fff;
}

.main-container .logo {
    margin: 30px auto 20px;
    max-width: 160px;
    width: 90%;
}

.main-container .app {
    width: 100%;
    max-width: 330px;
}

.main-container h1 {
    margin: 15px auto;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 40px;
    color: #fff;
}

.main-container h2 {
    margin: 15px auto;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 28px;
    color: #fff;
}

.main-container h3 {
    margin: 15px auto;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #fff;
}


.main-container p {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    line-height: 26px;
    font-weight: lighter;
    color: #fff;
}

.hero-container, .bookclub-hero-container, .year-hero-container {
    padding-bottom: 14%;
}

.year-hero-container {
   padding-bottom: 20px;
}
/* 
.user-stats {
    padding-bottom: 60px;
} */

.hero-container .floats, .bookclub-hero-container .floats {
    padding-top: 20px;
}

.hero-container .floats h1, .bookclub-hero-container .floats h1{
    font-size: 42px;
    margin-top: 5%;
}

.hero-container .floats h2, .bookclub-hero-container .floats h2{
    font-size: 24px;
}


.hero-container .floats .float-left p, .bookclub-hero-container .floats .float-left p {
    padding-top:10px;
}

.main-container .float-left .media-1 {
    height: 25px;
    margin-right:20px;
    max-width: 100px;
}
.main-container .float-left .media-2 {
    height: 25px;
    margin-top: 5px; 
    max-width: 150px;
}

button {
        background-color: #8E42D1; 
        border: none;
        color: white;
        padding: 15px 32px;
        border-radius: 50px;
        font-family: 'Lato', sans-serif;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 18px;
        transition: all 2s ease;  
}

button:hover {
    text-decoration: none;
    position: relative;
    top: -2px;       
}

.app-store {
    transition: all 2s ease; 
}

.app-store:hover {
    text-decoration: none;
    position: relative;
    top: -2px;       
}

#mc_embed_signup { border: none; text-align: center; width: 100%; } /* Signup form container */

.mc-field-group { display: inline-block; } /* positions input field horizontally */

#mce-EMAIL { 
    font-family: 'Lato', sans-serif; 
    font-size: 2em; 
    border: 2px solid #fff ; 
    color: #333; 
    background-color: #fff; 
    padding: .7em 1em; 
    width: 220px;
    -webkit-border-radius: 2px; 
    -moz-border-radius: 2px; 
    border-radius: 10px; 
    display: inline-block; 
    margin: 0 10px; 
} /* Input Styles */

.clear { 
    display: inline-block; 
} /* positions button horizontally in line with input */

#mc_embed_signup .button { 
    font-family: 'Lato', sans-serif; 
    font-size: 2em; 
    letter-spacing: .03em; 
    color: #fff; 
    background-color: #8e42d1 ; 
    padding: .7em 1em; 
    border: 2px solid #8e42d1 ; 
    max-width: 100px;
    -webkit-border-radius: 3px; 
    -moz-border-radius: 3px; 
    border-radius: 10px; 
    display: inline-block; 
    margin: 10px auto; } /* Button Styles */

:-webkit-input-placeholder { color: #333 ; } /* WebKit browsers */ 
:-moz-placeholder { color: #333 ; } /* Mozilla Firefox 4 to 18 */
::-moz-placeholder { color: #333 ; } /* Mozilla Firefox 19+ */
:-ms-input-placeholder { color: #333 ; } /* Internet Explorer 10+ */

.year-in-review {
    background-color: #4A4A4A;
}

.year-in-review .main-container .row .year-left img{
    max-width: 50%;
}

.year-in-review .main-container .row .year-right button {
    background: #8E42D1;
    background-image: linear-gradient(to right, #1CC5DD 0%,  #8E42D1 100%)
    }


.steps .main-container, .top-badges .main-container, .bs-numbers .main-container {
    text-align: center;
    /* margin-top: 40px; */
}

.steps .main-container .row, .top-badges .main-container .row {
    padding-bottom: 0px;
}

.steps .main-container .row .icon{
    max-width: 142px;
}

.top-badges .main-container .row .fav-badge, .bs-numbers .main-container .row .fav-badge{
    max-width: 115px;
}


.steps .main-container .row .col-xs-12, .top-badges .main-container .row .col-xs-12, .bs-numbers .main-container .row .col-xs-12 {
    padding-bottom: 30px;
}

.steps .main-container h1, .top-badges .main-container h1, .bs-numbers .main-container h1 {
    font-size: 40px; 
    color: #333;
    margin-top: 20px;
}


.steps .main-container p, .top-badges .main-container h2, .bs-numbers .main-container h2 {
    color: #333;
}

.top-badges .main-container h2, .bs-numbers .main-container h2 {
    padding-bottom: 40px;
}

.features {
    background-color: #fff;
}

.features .main-container .row {
    padding-top: 20px;
}

.features .main-container .first-row .featured-profile{
    max-width: 90%;
    display: block;
    margin: 0 auto;
}

.features .main-container .row .featured-app{
    max-width: 280px;
}

.features .main-container h1, .features .main-container p {
    color: #333;
    text-align: center;
}

.features .row .discover-img, .features .row .rating-img {
    text-align: center;
}

.footer {
    margin-top: 40px;
}

.footer .main-container {
    text-align: center;
}

.footer .main-container .modal .modal-header {
        border-bottom: none;
}
    
.footer .main-container .modal-body h1, .footer .main-container .modal-body h2 {
    color: #333;
}

.footer .main-container .modal-content .modal-header, .footer .main-container .modal-content .modal-body {
    padding: 0;
}

.footer .main-container .modal-content button.close {
    margin: 10px;
}
    
.footer .main-container .modal-body button {
    padding: 10px 30px;
}

.footer .main-container .modal-body input[type=number]::-webkit-inner-spin-button,
.footer .main-container .modal-body input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.footer .main-container .modal-body input {
    font-family: 'Chivo', sans-serif;
    font-size: 2.5vw;
    color: #8E42D1;
    text-align: center;
    border: none;
    border-bottom: 2px solid #8E42D1;
    width: 100px;
}

.footer .main-container .modal-body input:focus {
    outline: none; 
}

.footer .main-container .download-bs {
    padding: 30px 0;
}

.footer .main-container .moodal.modal-footer {
    border-top: none;
}

.footer .main-container button {
    margin-top: 20px;
    margin-bottom: 60px;
}



.footer .app-download {
    text-align: center;
}

.footer .app-download a{
    display: block;
    padding: 15px 0;
}

.footer h3 {
    text-align: center;
}

.footer-links {
    padding-top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.footer-links p {
    margin: 0;
    padding-right: 20px;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    display: block;
    padding-bottom: 20px;
}

.footer-links .social-media {
    text-align: center;
}

.footer-links .social-media p {
    display: block;
    padding-top: 15px;
}

.footer-links .social-logo {
    display: inline-block;
}

.footer-links .social-logo img {
    height: 30px;
    margin: 0px 10px 10px 0;
}

.footer-links .links a p:hover{
    text-decoration: underline;
}

.footer .footer-links .links{
    text-align: center;
}

.footer .footer-links .links p{
    text-align: center;
    display: inline-block;
    padding-bottom: 20px;
}

.terms-privacy h2 {
    font-size: 24px;
    font-weight: 800;
}

.terms-privacy p {
    text-align: left;
}

.terms-privacy li {
    list-style: none; 
}

.terms-privacy p span{
    font-weight: 800;
}

.terms-privacy .footer-links p {
    color: #fff;
    text-align: center;
}

.terms-privacy .footer-links .links {
    text-align: center;
}

.terms-privacy .footer-links .links a p:hover{
    text-decoration: underline;
}

.year-hero-container {
    padding: 0;
}

.year-hero-container .main-container {
    padding: 100px 0;
    background: linear-gradient(45deg,rgba(28,197,221,0.85),rgba(142,66,209, 0.85)) fixed;
    width: 100%;
}

.year-hero-container .main-container .year-hero-text {
    text-align: center;
    max-width: 100%;
}

.year-hero-container .main-container .year-hero-text h1 {
    font-size: 12vw;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.year-hero-container {
    position: relative;
}

.year-hero-container .background-books {
    background-image: url(./year-hero-background.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.year-hero-container .main-container .year-hero-text h2 {
    font-family: 'Nunito', sans-serif;
}

.year-hero-container .main-container .year-hero-text p {
    font-family: 'Nunito', sans-serif;
    padding-top: 0;
    text-align: center;
}

.year-hero-container .main-container .year-hero-text button {
    padding: 2px 22px;
}

.year-hero-container .main-container .year-hero-text button.log-in {
    background: #8e42d1;
    border-radius: 18px;
    height: 36px;
    padding: 0px 20px 0px 20px;
}

.library-background-container, .share-friends {
    background-image: url(https://s3.amazonaws.com/dev-www.booksloth-apis.com/static/media/background_image_1440.cf846c5d.svg);
    background-size: cover;
}

.library-background-container .row{
    padding: 30px 0;
}

.library-background-container .main-container {
    max-width: 80%;
}

.library-background-container .rating-txt p{
    text-align: center;
}

.library-background-container .rating-img{
    text-align: center;
}
.library-background-container .rating-img .sloth-stats {
    max-width: 50%;
}

.Top-Books-Section-Caption-Text, .Top-Authors-Section-Caption-Text {
    font-family: "Lato",sans-serif;
    font-weight: 300;
}

.steps.top-genres .top-genre {
    background-color: #f4f4f4;
    border-radius: 30px;
    padding: 20px;
}

.bs-top-books, .top-genres {
    padding-bottom: 0;
}

.steps.top-genres .top-genre h1, .steps.top-genres .top-genre h2, .steps.top-genres .top-genre p {
    font-family: 'Nunito', sans-serif;
}

.steps.top-genres .top-genre h2 {
    margin: 0;
}

.steps.top-genres .top-genre .number {
    color:#8e42d1;
    font-size: 40px;

}

.steps.top-genres .top-genre img {
    max-height: 100px;
    margin: 20px;
}

.footer-like-container {
    /* height: 100%; */
    background-image: url(https://dev-www.booksloth-apis.com/social-media-background.png);
    background-repeat: no-repeat;
    background-size: 140%;
    background-position: top;
}

.footer-like-container .float-right h1 {
    margin: 15px auto;
    font-family: 'Nunito', sans-serif;
    font-weight: 800;
    font-size: 40px;
    color: #fff;
}

.footer-like-container .float-right h2 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    color: #a852d7;
}

.footer-like-container .float-right{
    padding-top: 30px;
}

.footer-like-container .Social-Media-Icon-Container {
    padding-bottom: 30px;
}


.footer-company-container .Footer-Company-Section{
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    text-align: center;
    height: auto;
}

.footer-company-container .Footer-Company-Section .Footer-Logo {
    width: 230px;
}

.footer-company-container .Footer-Company-Section .Footer-Social-Icon{
    height: 40px;
    margin: 10px;
}

.footer-company-container .Footer-Company-Section .Footer-Company-Section-Text {
    color: #57325e;
    font-size: 18px;
    font-weight: 600;
    font-family: "Nunito",sans-serif;
    color: #57325e;
}

.UserBookStatistics-Section-Sub-Macro {
    padding: 4% 8%;
}

.UserBookStatistics-Section-Header {
    font-size: 42px;
    line-height: 50px;
}

.UserBookStatistics-Section-Count {
    font-size: 42px;
    padding-top: 20px
}


.Book-Item-Image {
    width: 165px;
}

.Book-Item-Header {
	font-size: 18px;
}


.Nav-Bar-Booksloth-Link, .Nav-Bar-Link {
	text-decoration: none;
}

.Top-Books-Image {
	width: 100%;
}

.top-badges {
    padding: 5% 0;
}

.bs-numbers {
    padding: 5% 0;
}

.Social-Media-Sub-Macro-Container {
padding: 40px 0; 
display: flex;
width: 100%;
align-content: center;
flex-direction: row-reverse;
}

.Social-Media-Content-Container {
    margin-right: 10%;
}

.Social-Media-Title {
    font-size: 34px;
    line-height: 40px;
}

.Social-Media-Sub-Title{
    font-size: 22px;
}

.Social-Media-Content-Container {
    height: auto;
}

.footer-company-container{    background: hsla(0,0%,100%,.7);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
}

.Footer-Company-Section {
    height: auto;
}

.Footer-Company-Section.row h2 {
    font-size: 28px;
    padding-top: 20px;
}

.Footer-Social-Container {
    width: 100%;
    padding: 20px 0; 
    margin: 0 auto;
}



.Footer-Legal-Section {
    background-color: #4a4a4a;
    width: 100%;
}


.Footer-Legal-Section .main-container {
    max-width: 80%;
}


.Footer-Legal-Section .Footer-Legal-Section-Text p {
    display: inline-block;
    font-size: 18px;
    font-family: "Nunito",sans-serif;
    padding: 0;
    margin: 0;
}


.Footer-Legal-Section .Footer-Legal-Section-Text .footer-legal-terms.float-right {
    margin: 0;
    float: none;
}
.Footer-Legal-Section .Footer-Legal-Section-Text .footer-legal-terms a {
    display: inline-block;
    font-size: 18px;
    font-family: "Nunito",sans-serif;
}

.Footer-Legal-Section .Footer-Legal-Section-Text .footer-legal-terms p {
    padding: 0 20px;
}

.Footer-Legal-Section .Footer-Legal-Section-Text a {
    color: #fff;
    height: auto;
    text-align: center;
}

.Footer-Company-Section-Text {
    text-decoration: none;
    display: block;
}

.Top-Books-div-Container {
    width: 80%;
    margin: 0 auto;
}


@media (min-width: 850px) { /* positions input field and button underneath each other with 100% width for tablet and mobile */

.year-hero-container .main-container {
    background: transparent;
    width: 80%;
}

.year-hero-container .main-container .year-hero-text {
    text-align: left;
    max-width: 50%;
}

.year-hero-container .main-container .year-hero-text p {
    text-align: left;
}

.bs-top-books, .top-genres {
    padding-bottom: 0;
}

.year-hero-container .background-books {
    background-image: url(./year-hero-background-17.png);
    background-attachment: fixed;
    background-size: contain;
    background-position: right;
}

.year-hero-container .main-container .year-hero-text h1 {
    font-size: 12vw;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.library-background-container, .share-friends {
    background-size: contain;
}

.library-background-container .rating-txt p{
    text-align: left;
}

.library-background-container .row{
    padding: 60px 0;    
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-like-container {
    background-size: cover;
}

.footer-like-container .float-right h1 {
    font-size: 60px;
}

.footer-like-container .float-right h2 {
    font-size: 42px;
}

.Social-Media-Content-Container {
    margin-right: 20%;
}

.footer-company-container .Footer-Company-Section{
    text-align: left;
}


.Footer-Legal-Section .main-container {
    max-width: 80%;
}


.Footer-Legal-Section .Footer-Legal-Section-Text p {
    display: inline-block;
    font-size: 18px;
    font-family: "Nunito",sans-serif;
    padding: 0;
    margin: 0;
}

.Footer-Social-Container {
    margin: 0;
}


.Footer-Legal-Section .Footer-Legal-Section-Text .footer-legal-terms {
    padding-top: 0;
    float: right;
}

.Footer-Legal-Section .Footer-Legal-Section-Text .footer-legal-terms a {
    display: inline-block;
    font-size: 18px;
    font-family: "Nunito",sans-serif;
}

.hero-container, .footer, .bookclub-hero-container {
    padding-top: 60px;
}

.hero-container .float-left, .bookclub-hero-container .float-left {
    padding-bottom: 0;
}


/* .main-container .nav-links {
    max-width: 75%;
} */



.main-container .nav-right {
    width: 80%;
    display: inline-block;
    text-align: right;
}

.main-container {
    width: 100%;
    text-align: left;
}

.year-hero-container .main-container, .user-stats .main-container{
    text-align: center;
}

.year-hero-container .main-container, .user-stats .main-container {
        text-align: center;
}

.top-genres, .bs-top-books {
    padding-bottom: 0px;
}

.top-genres .main-container #myCarousel .fav-genre .carousel-caption,
.bs-top-books.main-container #myCarousel .fav-genre .carousel-caption {
    bottom: 20px;
}

.top-genres .main-container #myCarousel .fav-genre .carousel-caption h1,
.bs-top-books .main-container #myCarousel .fav-genre .carousel-caption h1 {
    font-size: 40px;
} 

.top-genres .main-container #myCarousel .fav-genre .carousel-caption h2,
.bs-top-books .main-container #myCarousel .fav-genre .carousel-caption h2 {
    font-size: 32px
}

.top-genres .main-container #myCarousel .carousel-indicators,
.bs-top-books .main-container #myCarousel .carousel-indicators {
    bottom: -20%;
}

/* .top-authors {
    padding-top: 60px;
    padding-bottom: 60px;
} */

.top-badges .main-container .notification.number {
    padding: 1.5%;
    position: absolute;
    top: -5%;
    right: 40%;
}

.main-container .app {
    margin-left: 0;
}

.main-container .float-left {
    width: 70%;
    z-index: 2;
    bottom: 0;
    display: inline-block;
}

.main-container .app {
    margin-left: 15%;
}

.hero-container .floats h1, .bookclub-hero-container .floats h1 {
    font-size: 72px;
    margin-top: 10%;
}

.main-container .float-left p{
    padding-top: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.hero-container .floats .float-left p, .bookclub-hero-container .floats .float-left p {
    padding-top:70px;
}

.main-container .float-left img.app-store {
    margin: 20px auto 15px;
    display: inline-block;
    max-width: 200px;
}

.main-container .float-left img.app-store.google { 
margin-left: 15px;
}

.main-container .float-left .media-1 {
    height: 25px;
    margin-right:20px;
    max-width: 100px;
}
.main-container .float-left .media-2 {
    height: 25px;
    margin-top: 5px; 
}

.main-container .float-right {
    width: 29%;
    position: relative;
    z-index: 100;
    display: inline-block;
    margin: 10% 0;
    float: right;
}

.year-in-review .main-container .row {
    padding: 30px;
}

.year-in-review .main-container .row .year-left img{
    max-width: 200px;
}

.year-in-review .main-container .row .year-right {
    padding: 50px 0;
}

.year-in-review .main-container .row .year-right p{
    text-align: left;
}

/* .steps .main-container .row, .top-badges .main-container .row, .bs-numbers .main-container .row {
    padding-bottom: 60px;
} */

.features .main-container .first-row {
    padding: 60px 0;
}


.features .main-container .first-row .featured-profile{
    max-width: 750px;
}

.features .main-container .row .featured-app{
    max-width: 350px;
}

.features .main-container .row {
    padding-top: 0px;
}

.features .main-container .row h1, .features .main-container .row p {
    text-align: left;
}
.features .row .community-txt, .features .row .rating-txt, .features .row .profile-txt {
    padding-top: 10%;
    padding: 20% 50px 0;
}

.features .row .community-img, .features .row .profile-img {
    float: left;
}

.features .row .community-txt, .features .row .profile-txt {
    float: right;
}

.features .row .rating-img {
    float: right;
}

.features .row .rating-txt {
    float: left;
}

.footer .app-download {
    padding-bottom: 20px;
    text-align: center;
}

.footer .app-download a{
    display: inline-block;
    padding: 0;
}

.footer .app-download .google{
    padding-left: 20px;
}

}

@media (min-width: 990px) {

.top-badges .main-container .notification.number {
    padding: 2.5%;
    position: absolute;
    top: -5%;
    right: 30%;
}


}