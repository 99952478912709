.Statistics-Section-Macro {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center; 
    padding-top: 40px;
    padding-bottom: 40px;

}

.Statistics-Section-Macro .main-container{
    text-align: center;
}

.Statistics-Section-Macro svg{
    overflow: visible;
}

.Statistics-Section-Macro .legend .legend-items {
    padding: 10% 2%;
    text-align: left;
    width: 50%;
    margin: 0 auto;
}


.Statistics-Section-Macro .main-container .row h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 28px;
    color: #4a4a4a;
    line-height: 30px;
    margin-bottom: 40px;
}

.Statistics-Section-Macro .year-books {
    padding-top: 30px;
}

.Statistics-Section-Macro .legend p {
    display: inline-flex;
    margin-bottom: 10px;
    color: #4a4a4a;
    line-height: 20px;
    font-size: 18px;
}

.Statistics-Section-Macro .legend span {
    margin-right: 20px;
}

.Statistics-Section-Macro .legend-circle {
    height: 20px;
    width: 20px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
}

.Statistics-Section-Header {
    display: block;
    font-size: 40px;
    font-family: 'Nunito', sans-serif;
    line-height: 50px;
    font-weight: 700;
    text-align: center;
    color: #4a4a4a;
}

.Statistics-Text-Caption {
    display: block;
    font-size: 22px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
    padding-top: 16px;
    color: #4a4a4a;
    margin-bottom: 20px;
}


