.Footer-Macro-Container {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    width: 100%;
    /* position: absolute; */
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    background: hsla(0,0%,100%,.7);
}

.Footer-Company-Section {
    margin-right: 12%;
    margin-left: 12%;
    margin-top: 55px;
    height: 180px;
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Footer-Company-Section-Macro-Container {
    display: flex;
    flex-direction: row;
}

.Footer-Company-Section-Subcontainer {
    width: auto;
    padding-top: 12px;
    padding-left: 80px;
}

.Footer-Company-Section-Text {
    display: inline-block;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    padding-top: 24px;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    color: #57325E;
}

.Footer-Company-Section-Text-Header {
    font-size: 28px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    color: #57325E;
}

.Footer-Legal-Section {
    background-color: #4A4A4A;
    width: 100%;
}

.Footer-Legal-Section-Text {
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    line-height: 70px;
    color: white;
    padding-top: 20px;
}

​
.Footer-Legal-Section-Text a {
	color: #fff;
}
​
.Footer-Company-Section-Text {
	text-decoration: none;
}

.Footer-Logo {
    width: 205px;
}

.Footer-Social-Icon {
    margin-right: 15px;
    height: 24px;
}

.Footer-Social-Icon, .instagram {
    margin-left: 1px;
}

.Footer-Social-Container {
    background-color: transparent;
    width: 205px;
    padding-top: 50px;
}

@media (min-width: 850px) { /* positions input field and button underneath each other with 100% width for tablet and mobile */

    .Footer-Legal-Section-Text {
        padding-top: 0;
    }

}