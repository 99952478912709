.Header-Section-Macro {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    height: 640px;
    background-image: url("./background_image_1440.svg");
}

.Header-Section-Sub-Macro-Top {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: inherit;
    justify-content: center;
    align-content: center;
}

.Header-Section-Sub-Macro-Background {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: inherit;
}

.Header-Section-Sub-Macro-Background-Image {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.Header-Section-Title {
    display: inline-block;
    font-size: 150px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    line-height: 80px;
    font-weight: 700;
    color: white;
    margin-bottom: 40px;
    text-align: center;
}

.Header-Section-Sub-Title {
    display: inline-block;
    font-size: 32px;
    font-family: 'Nunito', sans-serif;
    line-height: 80px;
    font-weight: 700;
    color: white;
    margin-bottom: 40px;
    text-align: center;
}

.Header-Section-Caption {
    display: inline-block;
    font-size: 22px;
    font-family: "Lato", sans-serif;
    line-height: 50px;
    font-weight: 300;
    color: white;
    text-align: center;
}

.Header-Section-Button {
    width: fit-content;
    margin-top: 40px;
    align-self: center;
}

@media only screen and (max-width: 1440px) {
    .Header-Section-Macro {
        height: 640px;
    }
    .Header-Section-Sub-Macro-Top {
        padding-top: 1%;
    }
    .Header-Section-Button {
        margin-top: 20px;
    }
    .Header-Section-Title {
        display: inline-block;
        font-size: 150px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        line-height: 80px;
        font-weight: 700;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Header-Section-Sub-Title {
        display: inline-block;
        font-size: 32px;
        font-family: 'Nunito', sans-serif;
        line-height: 80px;
        font-weight: 700;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Header-Section-Caption {
        display: inline-block;
        font-size: 22px;
        font-family: "Lato", sans-serif;
        line-height: 50px;
        font-weight: 300;
        color: white;
        text-align: center;
    }
    
    .Header-Section-Button {
        width: fit-content;
        margin-top: 40px;
        align-self: center;
    }
}

@media only screen and (max-width: 1280px) {
    .Header-Section-Macro {
        height: 620px;
    }
    .Header-Section-Sub-Macro-Top {
        padding-top: 1%;
    }
    .Header-Section-Button {
        margin-top: 20px;
    }
    .Header-Section-Title {
        display: inline-block;
        font-size: 120px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        line-height: 50px;
        font-weight: 700;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Header-Section-Sub-Title {
        display: inline-block;
        font-size: 32px;
        font-family: 'Nunito', sans-serif;
        line-height: 80px;
        font-weight: 700;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Header-Section-Caption {
        display: inline-block;
        font-size: 22px;
        font-family: "Lato", sans-serif;
        line-height: 40px;
        font-weight: 300;
        color: white;
        text-align: center;
    }
    
    .Header-Section-Button {
        width: fit-content;
        margin-top: 40px;
        align-self: center;
    }
}

@media only screen and (max-width: 960px) {
    .Header-Section-Macro {
        height: 510px;
    }
    .Header-Section-Sub-Macro-Top {
        padding-top: 2%;
    }
    .Header-Section-Button {
        margin-top: 20px;
    }
    .Header-Section-Button-Icon {
        height: 36px;
    }
    .Header-Section-Title {
        display: inline-block;
        font-size: 100px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-weight: 700;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Header-Section-Sub-Title {
        display: inline-block;
        font-size: 32px;
        font-family: 'Nunito', sans-serif;
        line-height: 40px;
        font-weight: 700;
        color: white;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Header-Section-Caption {
        display: inline-block;
        font-size: 22px;
        font-family: "Lato", sans-serif;
        line-height: 30px;
        font-weight: 300;
        color: white;
        text-align: center;
    }
    
    .Header-Section-Button {
        width: fit-content;
        margin-top: 20px;
        align-self: center;
    }
}

@media only screen and (max-width: 840px) {
    .Header-Section-Macro {
        height: 380px;
    }
    .Header-Section-Sub-Macro-Top {
        padding-top: 4%;
    }
    .Header-Section-Button {
        margin-top: 20px;
    }
    .Header-Section-Button-Icon {
        height: 36px;
    }
    .Header-Section-Sub-Title {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0px;
    }
    .Header-Section-Title {
        font-size: 44px;
        line-height: normal;
        margin-bottom: 0px;
    }
    .Header-Section-Caption {
        font-size: 17px;
        line-height: normal;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .Header-Section-Macro {
        height: 375px;
    }
    .Header-Section-Sub-Macro-Top {
        padding-top: 5%;
    }
    .Header-Section-Button {
        margin-top: 20px;
    }
    .Header-Section-Button-Icon {
        height: 36px;
    }
    .Header-Section-Sub-Title {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0px;
    }
    .Header-Section-Title {
        font-size: 44px;
        line-height: normal;
        margin-bottom: 0px;
    }
    .Header-Section-Caption {
        font-size: 17px;
        line-height: normal;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 480px) {
    .Header-Section-Macro {
        height: 375px;
    }
    .Header-Section-Sub-Macro-Top {
        padding-top: 5%;
    }
    .Header-Section-Button {
        margin-top: 20px;
    }
    .Header-Section-Button-Icon {
        height: 36px;
    }
    .Header-Section-Sub-Title {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 0px;
    }
    .Header-Section-Title {
        font-size: 44px;
        line-height: normal;
        margin-bottom: 0px;
    }
    .Header-Section-Caption {
        font-size: 17px;
        line-height: normal;
        margin-bottom: 0px;
    }
}