.No-Statistics-Macro-Background {
    width: 100%;
    margin-top: 32px;
    justify-content: center;
    align-content: center;
    display: flex;
}

.No-Statistics-Sub-Macro-Background {
    display: flex;
    flex-direction: column;
    background-color: #F3F4F5;
    border-radius: 32px;
    height: 700px;
    justify-content: space-around;
    width: 1440px;
    align-items: center;
    padding: 24px;
    margin-right: 12%;
    margin-left: 12%;
}

.No-Statistics-Button {
    width: fit-content;
    align-content: center;
    display: flex;
}

.No-Statistics-Text-Header {
    display: block;
    font-size: 36px;
    font-family: 'Nunito', sans-serif;
    line-height: 64px;
    font-weight: 700;
    text-align: center;
}

.No-Statistics-Text-Caption {
    display: block;
    font-size: 24px;
    font-family: 'Nunito', sans-serif;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
}

.No-Statistics-Image {
    height: 250px;
}

@media only screen and (max-width: 1024px) {
    .No-Statistics-Sub-Macro-Background {
        margin-right: 5%;
        margin-left: 5%;
        height: 520px;
    }

    .No-Statistics-Image {
        height: 181px;
    }

    .No-Statistics-Text-Header {
        font-size: 24px;
    }
    
    .No-Statistics-Text-Caption {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .No-Statistics-Sub-Macro-Background {
        margin-right: 5%;
        margin-left: 5%;
        height: 520px;
    }

    .No-Statistics-Image {
        height: 120px;
    }

    .No-Statistics-Text-Header {
        font-size: 24px;
        line-height: 32px;
    }
    
    .No-Statistics-Text-Caption {
        font-size: 16px;
        line-height: 24px;
    }

    .No-Statistics-Button {
        width: fit-content;
        align-content: center;
        display: flex;
    }
}